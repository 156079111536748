<template>
  <div class="addUser-dialog">
    <base-dialog
      :model="addData.isOpen"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form ref="form" :model="formData" label-width="100px">
          <el-form-item label="参数名" prop="dictTitle">
            <el-input v-model.trim="formData.dictTitle"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="dictDesc">
            <el-input v-model.trim="formData.dictDesc"></el-input>
          </el-form-item>
          <el-form-item label="参数健" prop="dictKey">
            <el-input v-model.trim="formData.dictKey"></el-input>
          </el-form-item>
          <el-form-item label="参数值" prop="dictValue">
            <el-input v-model.trim="formData.dictValue"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="dictSort">
            <el-input type="number" v-model.trim="formData.dictSort"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addParams">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { addParams, getParamsById } from '@/api/api'
export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        dictDesc: '', // 描述
        dictKey: '', // 参数key
        dictValue: '', // 参数value
        dictTitle: '', // 参数name
        dictSort: ''
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (isOpen && this.addData.type === 'edit') {
        this.getParamsById()
      }
      if (!isOpen) {
        this.resetFormData()
      }
    }
  },
  methods: {
    // 根据id获取参数信息
    getParamsById () {
      getParamsById(this.addData.id).then(res => {
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
        this.formData.id = this.addData.id
      })
    },
    // 添加参数
    addParams () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addParams(this.formData).then(res => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 重置表单
    resetFormData () {
      this.formData = {
        dictDesc: '', // 描述
        dictKey: '', // 参数key
        dictValue: '', // 参数value
        dictTitle: '', // 参数name
        dictSort: ''
      }
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
